import { commaNAFormatter } from 'Utilities/formatters';

import { createColumnHelper } from '@tanstack/react-table';

const columnHelper = createColumnHelper();

const FYColumnSetGenerator = (mainHeader, headerDisplayPrefix, keyPrefix, idPrefix) =>
    columnHelper.group({
        id: idPrefix,
        header: mainHeader,
        enableSorting: false,
        meta: {
            csvExportIgnore: true,
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right',
            cellStyle: { whiteSpace: 'normal' }
        },
        columns: [
            {
                id: `${idPrefix}-total`,
                accessorKey: `${keyPrefix}Total`,
                header: 'Total',
                meta: {
                    headerDisplayName: `${headerDisplayPrefix} Total`,
                    columnClassName: 'text-right',
                    columnStyle: { whiteSpace: 'pre-wrap' },
                    cellClassName: 'text-right',
                    cellStyle: { whiteSpace: 'normal' },
                    formatter: commaNAFormatter
                }
            },
            {
                id: `${idPrefix}-inKey`,
                accessorKey: `${keyPrefix}ProvidedIn`,
                header: 'Provided In Market',
                meta: {
                    headerDisplayName: `${headerDisplayPrefix} Provided In Market`,
                    columnClassName: 'text-right',
                    columnStyle: { whiteSpace: 'pre-wrap' },
                    cellClassName: 'text-right',
                    cellStyle: { whiteSpace: 'normal' },
                    formatter: commaNAFormatter
                }
            },
            {
                id: `${idPrefix}-outKey`,
                accessorKey: `${keyPrefix}ProvidedOut`,
                header: 'Provided In Other Markets',
                meta: {
                    headerDisplayName: `${headerDisplayPrefix} Provided In Other Markets`,
                    columnClassName: 'text-right',
                    columnStyle: { whiteSpace: 'pre-wrap' },
                    cellClassName: 'text-right',
                    cellStyle: { whiteSpace: 'normal' },
                    formatter: commaNAFormatter
                }
            },
            {
                id: `${idPrefix}-purchased`,
                accessorKey: `${keyPrefix}Purchased`,
                header: 'Community Care',
                meta: {
                    headerDisplayName: `${headerDisplayPrefix} Community Care`,
                    columnClassName: 'text-right',
                    columnStyle: { whiteSpace: 'pre-wrap' },
                    cellClassName: 'text-right',
                    cellStyle: { whiteSpace: 'normal' },
                    formatter: commaNAFormatter
                }
            }
        ]
    });

const FYSectorColumnSetGenerator = (mainHeader, headerDisplayPrefix, keyPrefix, idPrefix) =>
    columnHelper.group({
        id: idPrefix,
        header: mainHeader,
        enableSorting: false,
        meta: {
            csvExportIgnore: true,
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right',
            cellStyle: { whiteSpace: 'normal' }
        },
        columns: [
            {
                id: `${idPrefix}-total`,
                accessorKey: `${keyPrefix}Total`,
                header: 'Total',
                meta: {
                    headerDisplayName: `${headerDisplayPrefix} Total`,
                    columnClassName: 'text-right',
                    columnStyle: { whiteSpace: 'pre-wrap' },
                    cellClassName: 'text-right',
                    cellStyle: { whiteSpace: 'normal' },
                    formatter: commaNAFormatter
                }
            },
            {
                id: `${idPrefix}-inKey`,
                accessorKey: `${keyPrefix}ProvidedIn`,
                header: 'Provided In Market',
                meta: {
                    headerDisplayName: `${headerDisplayPrefix} Provided In Market`,
                    columnClassName: 'text-right',
                    columnStyle: { whiteSpace: 'pre-wrap' },
                    cellClassName: 'text-right',
                    cellStyle: { whiteSpace: 'normal' },
                    formatter: commaNAFormatter
                }
            },
            {
                id: `${idPrefix}-purchased`,
                accessorKey: `${keyPrefix}Purchased`,
                header: 'Community Care',
                meta: {
                    headerDisplayName: `${headerDisplayPrefix} Community Care`,
                    columnClassName: 'text-right',
                    columnStyle: { whiteSpace: 'pre-wrap' },
                    cellClassName: 'text-right',
                    cellStyle: { whiteSpace: 'normal' },
                    formatter: commaNAFormatter
                }
            }
        ]
    });

const MarketColumns = (defaultYear, filter) => {
    if (!defaultYear || defaultYear.defaultYear === '') {
        return [];
    }

    if (filter === 'Sector Utilization') {
        return [
            {
                accessorKey: 'Hspc',
                header: 'HSPC',
                enableHiding: false,
                meta: {
                    headerDisplayName: 'HSPC',
                    columnClassName: 'text-center',
                    columnStyle: { whiteSpace: 'pre-wrap' },
                    cellClassName: 'text-center',
                    cellStyle: { whiteSpace: 'normal' }
                }
            },
            {
                accessorKey: 'Sector',
                header: 'Sector',
                enableHiding: false,
                meta: {
                    headerDisplayName: 'Sector',
                    columnClassName: 'text-center',
                    columnStyle: { whiteSpace: 'pre-wrap' },
                    cellClassName: 'text-center',
                    cellStyle: { whiteSpace: 'normal' }
                }
            },
            FYSectorColumnSetGenerator(
                defaultYear.defaultYearWithFiscalStringBaselineUtilization,
                defaultYear.defaultYearWithFiscalString,
                'Year0',
                defaultYear.defaultYear
            ),
            FYSectorColumnSetGenerator(
                defaultYear.fiveYearWithFiscalStringAllocationUtilization,
                defaultYear.fiveYearWithFiscalString,
                'Year5',
                defaultYear.fiveYearsOfDefault
            ),
            FYSectorColumnSetGenerator(
                defaultYear.tenYearWithFiscalStringAllocationUtilization,
                defaultYear.tenYearWithFiscalString,
                'Year10',
                defaultYear.tenYearsOfDefault
            ),
            FYSectorColumnSetGenerator(
                defaultYear.twentyYearWithFiscalStringAllocationUtilization,
                defaultYear.twentyYearWithFiscalString,
                'Year20',
                defaultYear.twentyYearsOfDefault
            )
        ];
    }

    const fyColumns = [
        FYColumnSetGenerator(
            `FY ${defaultYear.twoYearActual} Actual Utilization`,
            defaultYear.defaultYearWithFiscalString,
            'ActualYear2',
            defaultYear.twoYearActual
        ),
        FYColumnSetGenerator(
            `FY ${defaultYear.defaultYear} Actual Utilization`,
            defaultYear.defaultYearWithFiscalString,
            'ActualYear1',
            defaultYear.oneYearActual
        ),
        FYColumnSetGenerator(
            defaultYear.defaultYearWithFiscalStringBaselineUtilization,
            defaultYear.defaultYearWithFiscalString,
            'Year0',
            defaultYear.defaultYear
        ),
        FYColumnSetGenerator(
            defaultYear.fiveYearWithFiscalStringAllocationUtilization,
            defaultYear.fiveYearWithFiscalString,
            'Year5',
            defaultYear.fiveYearsOfDefault
        ),
        FYColumnSetGenerator(
            defaultYear.tenYearWithFiscalStringAllocationUtilization,
            defaultYear.tenYearWithFiscalString,
            'Year10',
            defaultYear.tenYearsOfDefault
        ),
        FYColumnSetGenerator(
            defaultYear.twentyYearWithFiscalStringAllocationUtilization,
            defaultYear.twentyYearWithFiscalString,
            'Year20',
            defaultYear.twentyYearsOfDefault
        )
    ];

    return filter === 'Sub-Market Utilization'
        ? [
              {
                  accessorKey: 'HspcGroup',
                  header: 'HSPCG',
                  enableHiding: false,
                  meta: {
                      headerDisplayName: 'HSPCG',
                      columnClassName: 'text-center',
                      columnStyle: { whiteSpace: 'pre-wrap' },
                      cellClassName: 'text-center',
                      cellStyle: { whiteSpace: 'normal' }
                  }
              },
              {
                  accessorKey: 'SubmarketId',
                  header: 'Submarket',
                  enableHiding: false,
                  meta: {
                      headerDisplayName: 'Submarket',
                      columnClassName: 'text-center',
                      columnStyle: { whiteSpace: 'pre-wrap' },
                      cellClassName: 'text-center',
                      cellStyle: { whiteSpace: 'normal' }
                  }
              },
              ...fyColumns
          ]
        : filter === 'Market Level Utilization' || filter === 'Market Level RVU'
        ? [
              {
                  accessorKey: 'HspcGroup',
                  header: 'HSPCG',
                  enableHiding: false,
                  meta: {
                      headerDisplayName: 'HSPCG',
                      columnClassName: 'text-center',
                      columnStyle: { whiteSpace: 'pre-wrap' },
                      cellClassName: 'text-center',
                      cellStyle: { whiteSpace: 'normal' }
                  }
              },
              {
                  accessorKey: 'Hspc',
                  header: 'HSPC',
                  enableHiding: false,
                  meta: {
                      headerDisplayName: 'HSPC',
                      columnClassName: 'text-center',
                      columnStyle: { whiteSpace: 'pre-wrap' },
                      cellClassName: 'text-center',
                      cellStyle: { whiteSpace: 'normal' },
                      formatter: (value) => value.substr(value.indexOf(':') + 1).trim()
                  }
              },
              {
                  accessorKey: 'SubmarketId',
                  header: 'Submarket',
                  enableHiding: false,
                  meta: {
                      headerDisplayName: 'Submarket',
                      columnClassName: 'text-center',
                      columnStyle: { whiteSpace: 'pre-wrap' },
                      cellClassName: 'text-center',
                      cellStyle: { whiteSpace: 'normal' }
                  }
              },
              ...fyColumns
          ]
        : [
              {
                  accessorKey: 'HspcGroup',
                  header: 'HSPCG',
                  enableHiding: false,
                  meta: {
                      headerDisplayName: 'HSPCG',
                      columnClassName: 'text-center',
                      columnStyle: { whiteSpace: 'pre-wrap' },
                      cellClassName: 'text-center',
                      cellStyle: { whiteSpace: 'normal' }
                  }
              },
              ...fyColumns
          ];
};

export default MarketColumns;
