import React from 'react';
import { Alert, Nav } from 'react-bootstrap';
import { connect } from 'react-redux';

import DDFContainer from 'Lib/DDFSlides/DDFContainer';
import SubTabComponent from 'Lib/Tabs/SubTabComponent';
import { getReviewProjectedDemandSelector } from 'Modules/UnderstandDemand/Services/selector';
import { getCurrentMarketName } from 'Modules/UserSession/selector';

/**
 * Review Protected Demand in the Understand Demand module.
 *
 * @author Anthony P. Pancerella
 * @author Brandan D. Reed
 */
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import UDRPDFacilityTable from './UDRPDFacilityTable';
import UDRPDMarketTable from './UDRPDMarketTable';
import UDRPDPatientOriginModal from './UDRPDPatientOriginModal';

class Master extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: 0,
            subActiveTab: 'subTab1'
        };
    }

    componentWillMount() {
        const {
            marketUtilization,
            marketRvu,
            facilityUtilization,
            facilityRvu,
            hspcLevelUtilization,
            hspcLevelRvu,
            sectorWorkloadUtilization
        } = this.props.reviewProjectedDemand;
        const { marketName } = this.props;

        // MarketUtilization
        if (this.state.activeTab === 0 && marketName && marketUtilization.list.length === 0) {
            this.props.getMarketWorkload(marketName, 0, '');
            this.props.getMarketWorkload(marketName, 1, '');
            this.props.getMarketWorkload(marketName, 0, 'submarket');
            this.props.getSectorWorkload(marketName, 0);
        }

        // MarketRvu
        if (this.state.activeTab === 3 && marketName && marketRvu.list.length === 0) {
            this.props.getMarketWorkload(marketName, 1, '');
            this.props.getMarketWorkload(marketName, 1, 'submarket');
            this.props.getMarketWorkload(marketName, 1, 'level');
            this.props.getSectorWorkload(marketName, 0);
        }

        // FacilityRvu
        if (this.state.activeTab === 1 && marketName && facilityUtilization.list.length === 0) {
            this.props.getFacilityWorkload(marketName, 0);
            this.props.getFacilityWorkload(marketName, 1);
        }

        // FacilityUtilization
        if (this.state.activeTab === 3 && marketName && facilityRvu.list.length === 0) {
            this.props.getFacilityWorkload(marketName, 1);
        }

        if (this.state.activeTab === 0 && marketName && hspcLevelUtilization.list.length === 0) {
            this.props.getMarketWorkload(marketName, 0, 'level');
        }

        if (this.state.activeTab === 3 && marketName && hspcLevelRvu.list.length === 0) {
            this.props.getMarketWorkload(marketName, 1, 'level');
        }
    }

    componentWillUpdate(nextProps, nextState) {
        const {
            marketUtilization,
            marketRvu,
            facilityUtilization,
            facilityRvu,
            subMarketRvu,
            hspcLevelUtilization,
            hspcLevelRvu,
            sectorWorkloadUtilization
        } = this.props.reviewProjectedDemand;
        const { marketName } = this.props;
        const { marketName: nextMarketName } = nextProps;

        // Market
        if (
            (nextState.activeTab !== this.state.activeTab &&
                nextState.activeTab === 0 &&
                marketUtilization.list.length === 0) ||
            (nextState.activeTab === 0 && nextMarketName !== marketName)
        ) {
            this.props.getMarketWorkload(nextMarketName, 0, '');
            this.props.getMarketWorkload(nextMarketName, 1, '');
            this.props.getMarketWorkload(nextMarketName, 0, 'submarket');
            this.props.getMarketWorkload(nextMarketName, 1, 'submarket');
            this.props.getSectorWorkload(nextMarketName, 0);
        }
        if (
            (nextState.activeTab !== this.state.activeTab &&
                nextState.activeTab === 2 &&
                marketRvu.list.length === 0) ||
            (nextState.activeTab === 2 && nextMarketName !== marketName)
        ) {
            this.props.getMarketWorkload(nextMarketName, 1, '');
        }
        if (
            (nextState.activeTab !== this.state.activeTab &&
                nextState.activeTab === 2 &&
                subMarketRvu.list.length === 0) ||
            (nextState.activeTab === 2 && nextMarketName !== marketName)
        ) {
            this.props.getMarketWorkload(nextMarketName, 1, 'submarket');
        }

        if (
            (nextState.activeTab !== this.state.activeTab &&
                nextState.activeTab === 0 &&
                hspcLevelUtilization.list.length === 0) ||
            (nextState.activeTab === 0 && nextMarketName !== marketName)
        ) {
            this.props.getMarketWorkload(nextMarketName, 0, 'level');
            this.props.getMarketWorkload(nextMarketName, 1, 'level');
        }

        if (
            (nextState.activeTab !== this.state.activeTab &&
                nextState.activeTab === 2 &&
                hspcLevelRvu.list.length === 0) ||
            (nextState.activeTab === 2 && nextMarketName !== marketName)
        ) {
            this.props.getMarketWorkload(nextMarketName, 1, 'level');
        }

        // Facility
        if (
            (nextState.activeTab !== this.state.activeTab &&
                nextState.activeTab === 1 &&
                facilityUtilization.list.length === 0) ||
            (nextState.activeTab === 1 && nextMarketName !== marketName)
        ) {
            this.props.getFacilityWorkload(nextMarketName, 0);
            this.props.getFacilityWorkload(nextMarketName, 1);
        }
        if (
            (nextState.activeTab !== this.state.activeTab &&
                nextState.activeTab === 3 &&
                facilityRvu.list.length === 0) ||
            (nextState.activeTab === 3 && nextMarketName !== marketName)
        ) {
            this.props.getFacilityWorkload(nextMarketName, 1);
        }
    }

    changeTab = (tab) => {
        this.setState({ activeTab: tab, subActiveTab: 'subTab1' });
    };

    handleSubTabClick = (tab) => {
        this.setState({ subActiveTab: tab });
    };

    render() {
        const tabs = [
            {
                key: 0,
                label: 'Market Utilization'
            },
            {
                key: 1,
                label: 'Facility Utilization'
            },
            {
                key: 2,
                label: 'Market RVUs'
            },
            {
                key: 3,
                label: 'Facility RVUs'
            }
        ];

        const notice =
            this.state.activeTab === 0 || this.state.activeTab === 1 ? (
                <Alert role="note" variant="secondary" className="mt-3 mb-0">
                    <FontAwesomeIcon icon={faInfoCircle} fixedWidth />
                    <span style={{ paddingLeft: '5px' }}>
                        Utilization includes in-market and out-of-market demand data.
                    </span>
                </Alert>
            ) : null;

        return (
            <div>
                <DDFContainer
                    text={'There is relevant data published in the Data Discovery and Findings.'}
                    reports={[
                        {
                            title: 'Estimated Current and Historic Market Demand - Outpatient',
                            subSections: [
                                {
                                    sectionHeader: 'Primary Care',
                                    sectionSlides: [26, 27, 28, 29, 30, 31, 165]
                                },
                                {
                                    sectionHeader: 'Outpatient',
                                    sectionSlides: [25, 33, 44, 47, 48, 46]
                                },
                                {
                                    sectionHeader: 'Specialty Care',
                                    sectionSlides: [35, 36, 37, 162, 39]
                                },
                                {
                                    sectionHeader: 'Urgent/Emergency',
                                    sectionSlides: [43, 169, 42, 156, 168]
                                },
                                {
                                    sectionHeader: 'Telehealth',
                                    sectionSlides: [40, 41, 161]
                                },
                                {
                                    sectionHeader: 'Mental Health',
                                    sectionSlides: [32, 34]
                                },

                                {
                                    sectionHeader: 'Core Trends and Top Services',
                                    sectionSlides: [24, 49]
                                }
                            ]
                        },
                        {
                            title: 'Estimated Future Market Demand - Outpatient',
                            subSections: [
                                {
                                    sectionHeader: 'Workload',
                                    sectionSlides: [56, 57, 58]
                                },
                                {
                                    sectionHeader: 'Outpatient Utilization Projections',
                                    sectionSlides: [52, 53, 54, 55, 189]
                                },
                                {
                                    sectionHeader:
                                        'Outpatient Parent Facility Utilization Projections',
                                    sectionSlides: [60, 61, 62, 63, 190],
                                    isFacilityReport: true
                                },
                                {
                                    sectionHeader: 'Facility and Overall Reports',
                                    sectionSlides: [51, 59, 64, 196]
                                }
                            ]
                        },
                        {
                            title: 'Estimated Current and Historic Market Demand - Inpatient',
                            subSections: [
                                {
                                    sectionHeader: 'Inpatient Demand',
                                    sectionSlides: [66]
                                },
                                {
                                    sectionHeader: 'Medical/Surgical BDOC',
                                    sectionSlides: [67, 68, 75, 77]
                                },
                                {
                                    sectionHeader: 'Mental Health',
                                    sectionSlides: [71, 72, 73]
                                },
                                {
                                    sectionHeader: 'Nursing Homes',
                                    sectionSlides: [78, 79, 80, 81]
                                },
                                {
                                    sectionHeader: 'Surgical Cases',
                                    sectionSlides: [84]
                                },
                                {
                                    sectionHeader: 'Community Care & Rehab',
                                    sectionSlides: [74, 82, 83]
                                },
                                {
                                    sectionHeader: 'Top in Field',
                                    sectionSlides: [69, 70, 157, 158, 159]
                                }
                            ]
                        },
                        {
                            title: 'Estimated Future Market Demand - Inpatient',
                            subSections: [
                                {
                                    sectionHeader:
                                        'Market Utilization Projections (Acute Inpatient)',
                                    sectionSlides: [86, 88]
                                },
                                {
                                    sectionHeader:
                                        'Market Utilization Projections (Other Inpatient)',
                                    sectionSlides: [87, 89]
                                },
                                {
                                    sectionHeader: 'Overall Inpatient Commercial Demand',
                                    sectionSlides: [90, 197]
                                }
                            ]
                        }
                    ]}
                ></DDFContainer>
                <div>
                    <div style={{ paddingTop: 15 }}>
                        <SubTabComponent
                            activeTab={this.state.activeTab}
                            tabs={tabs}
                            handleOnClick={this.changeTab}
                        />
                    </div>
                    <div>
                        {notice}
                        {this.state.activeTab === 0 || this.state.activeTab === 2 ? (
                            <Nav
                                variant="pills"
                                defaultActiveKey="market-data"
                                style={{ paddingTop: 15 }}
                            >
                                <Nav.Item>
                                    <Nav.Link
                                        eventKey="market-data"
                                        onClick={() => this.handleSubTabClick('subTab1')}
                                    >
                                        Market Data
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link
                                        eventKey="sub-market-data"
                                        onClick={() => this.handleSubTabClick('subTab2')}
                                    >
                                        Sub-Market Data
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link
                                        eventKey="hspc-level-data"
                                        onClick={() => this.handleSubTabClick('subTab3')}
                                    >
                                        HSPC Level Data
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link
                                        eventKey="hspc-sector-level-data"
                                        onClick={() => this.handleSubTabClick('subTab4')}
                                    >
                                        Sector Level Data
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                        ) : null}
                        <div style={{ paddingTop: 15 }}>
                            {this.state.activeTab === 0 && this.state.subActiveTab === 'subTab1' ? (
                                <UDRPDMarketTable
                                    filter="Market Utilization"
                                    isExpandable
                                    exportName="-HspcGroupUtil.csv"
                                    tableType="market"
                                    expandTableType="Market RVUs"
                                />
                            ) : null}
                            {this.state.activeTab === 0 && this.state.subActiveTab === 'subTab2' ? (
                                <UDRPDMarketTable
                                    filter="Sub-Market Utilization"
                                    isExpandable
                                    exportName="-HspcSubmarketGroupUtil.csv"
                                    tableType="market"
                                    expandTableType="Sub-Market RVUs"
                                />
                            ) : null}
                            {this.state.activeTab === 0 && this.state.subActiveTab === 'subTab3' ? (
                                <UDRPDMarketTable
                                    filter="Market Level Utilization"
                                    isExpandable={false}
                                    exportName="-HspcMarketLevelGroupUtil.csv"
                                    tableType="market"
                                    expandTableType="Market Level RVUs"
                                />
                            ) : null}
                            {this.state.activeTab === 0 && this.state.subActiveTab === 'subTab4' ? (
                                <UDRPDMarketTable
                                    filter="Sector Utilization"
                                    isExpandable={false}
                                    exportName="-HspcSectorMarketUtil.csv"
                                    tableType="sector"
                                />
                            ) : null}
                            {this.state.activeTab === 1 ? (
                                <UDRPDFacilityTable
                                    filter="Facility Utilization"
                                    isExpandable
                                    exportName="-HspcGroupFacilityUtil.csv"
                                    tableType="facility"
                                    expandTableType="Facility RVUs"
                                />
                            ) : null}
                            {this.state.activeTab === 2 && this.state.subActiveTab === 'subTab1' ? (
                                <UDRPDMarketTable
                                    filter="Market RVUs"
                                    isExpandable={false}
                                    exportName="-HspcGroupRvu.csv"
                                    tableType="market"
                                />
                            ) : null}
                            {this.state.activeTab === 2 && this.state.subActiveTab === 'subTab2' ? (
                                <UDRPDMarketTable
                                    filter="Sub-Market Utilization"
                                    isExpandable={false}
                                    exportName="-HspcSubmarketGroupRvu.csv"
                                    tableType="market"
                                />
                            ) : null}
                            {this.state.activeTab === 2 && this.state.subActiveTab === 'subTab3' ? (
                                <UDRPDMarketTable
                                    filter="Market Level RVU"
                                    isExpandable={false}
                                    exportName="-HspcLevelRvu.csv"
                                    tableType="market"
                                />
                            ) : null}
                            {this.state.activeTab === 2 && this.state.subActiveTab === 'subTab4' ? (
                                <UDRPDMarketTable
                                    filter="Sector Utilization"
                                    isExpandable={false}
                                    exportName="-HspcSectorMarketUtil.csv"
                                    tableType="sector"
                                />
                            ) : null}
                            {this.state.activeTab === 3 ? (
                                <UDRPDFacilityTable
                                    filter="Facility RVUs"
                                    isExpandable={false}
                                    exportName="-HspcGroupFacilityRvu.csv"
                                    tableType="facility"
                                />
                            ) : null}
                        </div>
                    </div>
                </div>
                <UDRPDPatientOriginModal />
            </div>
        );
    }
}
const mapDispatchToProps = (dispatch) => ({
    getFacilityWorkload: (marketName, workloadType) =>
        dispatch.ReviewProjectedDemandModel.fetchFacilityWorkloadAsync({
            marketName,
            workloadType
        }),
    getMarketWorkload: (marketName, workloadType, marketType) =>
        dispatch.ReviewProjectedDemandModel.fetchMarketWorkloadAsync({
            marketName,
            workloadType,
            marketType
        }),
    getSectorWorkload: (marketName, workloadType) =>
        dispatch.ReviewProjectedDemandModel.fetchHspcSectorWorkloadAsync({
            marketName,
            workloadType
        })
});

const mapStateToProps = (state) => ({
    marketName: getCurrentMarketName(state),
    reviewProjectedDemand: getReviewProjectedDemandSelector(state)
});
export default connect(mapStateToProps, mapDispatchToProps)(Master);
