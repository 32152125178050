import {
    addNotification,
    HSPANotificationLevel
} from 'Modules/App/HSPANotifications/notifications';
import { getJSON } from 'Utilities/apiCalls';

const understandDemandApi = `${process.env.REACT_APP_HSPA_API}/UnderstandDemand/`;

export const ReviewProjectedDemandModel = {
    state: {
        marketUtilization: { list: [], errorMessage: '' },
        subMarketUtilization: { list: [], errorMessage: '' },
        facilityUtilization: { list: [], errorMessage: '' },
        sectorWorkloadUtilization: { list: [], errorMessage: '' },
        marketRvu: { list: [], errorMessage: '' },
        subMarketRvu: { list: [], errorMessage: '' },
        facilityRvu: { list: [], errorMessage: '' },
        hspcLevelUtilization: { list: [], errorMessage: '' },
        hspcLevelRvu: { list: [], errorMessage: '' },
        facilityModal: { show: false, facility: '', mpc: '', market: '', workloadType: '' }
    },
    reducers: {
        populate(state, data, tableName) {
            return {
                ...state,
                [tableName]: {
                    ...state[tableName],
                    list: data
                }
            };
        },
        updateLoading(state, isLoading, tableName) {
            return {
                ...state,
                [tableName]: {
                    ...state[tableName],
                    loading: isLoading
                }
            };
        },
        togglePOModal(state, payload) {
            return {
                ...state,
                facilityModal: {
                    show: !state.facilityModal.show,
                    facility: payload.facility,
                    mpc: payload.mpc,
                    market: payload.market,
                    workloadType: payload.workloadType
                }
            };
        },
        resetTable(state, tableName) {
            return {
                ...state,
                [tableName]: {
                    list: [],
                    loading: false
                }
            };
        },
        reset(state) {
            return {
                marketUtilization: { list: [], errorMessage: '' },
                subMarketUtilization: { list: [], errorMessage: '' },
                facilityUtilization: { list: [], errorMessage: '' },
                marketRvu: { list: [], errorMessage: '' },
                subMarketRvu: { list: [], errorMessage: '' },
                facilityRvu: { list: [], errorMessage: '' },
                sectorWorkloadUtilization: { list: [], errorMessage: '' },
                facilityModal: {
                    show: false,
                    list: [],
                    facility: '',
                    mpc: '',
                    market: '',
                    workloadType: ''
                }
            };
        }
    },
    effects: (dispatch) => ({
        async fetchFacilityWorkloadAsync(payload, state) {
            try {
                let tableName = payload.workloadType === 0 ? 'facilityUtilization' : 'facilityRvu';
                this.resetTable(tableName);
                this.updateLoading(true, tableName);
                let result = await getJSON(
                    `${understandDemandApi}GetHspcWorkloadInAndOutOfMarket?workloadType=` +
                        payload.workloadType +
                        '&marketName=' +
                        payload.marketName
                );
                this.updateLoading(false, tableName);
                this.populate(result, tableName);
            } catch (error) {
                this.updateLoading(false, payload.tableId);
                dispatch(
                    addNotification({
                        message: 'Error: Unable to fetch facility workload',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        },
        async fetchHspcSectorWorkloadAsync(payload, state) {
            try {
                const tableName = 'sectorWorkloadUtilization';
                this.resetTable(tableName);
                this.updateLoading(true, tableName);
                let result = await getJSON(
                    `${understandDemandApi}GetHspcSectorWorkload?marketName=${payload.marketName}`
                );
                this.updateLoading(false, tableName);
                this.populate(result, tableName);
            } catch (error) {
                this.updateLoading(false, payload.tableId);
                dispatch(
                    addNotification({
                        message: 'Error: Unable to fetch sector workload',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        },
        async fetchMarketWorkloadAsync(payload, state) {
            try {
                let tableName = '';
                if (payload.workloadType === 0)
                    tableName =
                        payload.marketType === 'submarket'
                            ? 'subMarketUtilization'
                            : payload.marketType === 'level'
                            ? 'hspcLevelUtilization'
                            : 'marketUtilization';
                else if (payload.workloadType === 1)
                    tableName =
                        payload.marketType === 'submarket'
                            ? 'subMarketRvu'
                            : payload.marketType === 'level'
                            ? 'hspcLevelRvu'
                            : 'marketRvu';

                this.resetTable(tableName);
                this.updateLoading(true, tableName);
                let result = await getJSON(
                    `${understandDemandApi}GetHspcWorkloadGroupByHspcGroupEnrolleeMarketInOutOfMarket?marketName=` +
                        payload.marketName +
                        '&workloadType=' +
                        payload.workloadType +
                        '&marketType=' +
                        payload.marketType
                );
                if (
                    tableName === 'subMarketUtilization' ||
                    tableName === 'marketUtilization' ||
                    tableName === 'hspcLevelUtilization'
                )
                    for (let i = 1; i < result.length; i++) result[i].Id = i;

                this.updateLoading(false, tableName);
                this.populate(result, tableName);
            } catch (error) {
                let tableName = '';
                if (payload.workloadType === 0)
                    tableName =
                        payload.marketType === 'submarket'
                            ? 'subMarketUtilization'
                            : (payload.marketType === 'level' && 'hspcLevelUtilization') ||
                              'marketUtilization';
                else if (payload.workloadType === 1)
                    tableName =
                        payload.marketType === 'submarket'
                            ? 'subMarketRvu'
                            : (payload.marketType === 'level' && 'hspcLevelRvu') || 'marketRvu';

                this.updateLoading(false, tableName);
                dispatch(
                    addNotification({
                        message: 'Error: Unable to fetch market workload',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        }
    })
};
